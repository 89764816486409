import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import styles from "./CustomerModal.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { compare, decrypt } from "n-krypta";

export default function CustomersModal(props) {
  const token = useSelector((state) => state.green.admin.login.token);

  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const key = "ShareeCoin_1234567";

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/ownerReport", {
        params: {
          merchantName: handleMerchant(decrypt(storedMerchant,key)).trim(),
          queryType: "totalRegistered",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setCount(res.data.registeredCount);
        } else {
          navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
        }
      });
  }, []);

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
      >
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>Customers</h4>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <div className={`${styles.bgWhite} p-3 mt-3`}>
                <h5>Registered Customers:</h5>
                <h2 className="text-center mb-3 mt-3">{count}</h2>
              </div>
              <Col
                xs={12}
                md={{ span: 6, offset: 3 }}
                className="text-center my-3"
              >
                <Button
                  variant="success"
                  size="md"
                  className={`${styles.downloadQrBtn}`}
                >
                  Download Data
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
