import React, { useEffect,useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Zoom } from "react-toastify";
import { deleteBranch } from "../../../../../redux/features/adminSlice";
import { IoMdCloseCircle } from "react-icons/io";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import { useTranslation } from "react-i18next";
import styles from "./DelBranch.module.scss";
import { compare, decrypt } from "n-krypta";

export default function DelBranch(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disableDelBtn, setDisableBtn] = useState(false);


  const token = useSelector((state) => state.green.admin.login.token);

  const { t, i18n } = useTranslation();

  const key = "ShareeCoin_1234567";

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
      >
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <div className={`${styles.closeIcon}`}>
            <IoMdCloseCircle
              onClick={() => {
                props.onHide();
              }}
            />
          </div>
          <Container>
            <Row>
              <Col xs={12} className="text-center mb-4 pt-3">
                <img
                  src={require("../../../../../imgs/Alert.png")}
                  alt="alert-message"
                  className={`${styles.alertIcon}`}
                />
              </Col>
              <Col xs={12} className="mt-3 text-center">
                <p>You're going to premanently delete this branch</p>
              </Col>
              <Col xs={12} className="text-center mt-3">
                <Button
                  variant="danger"
                  size="sm"
                  className={`${styles.delBtn}`}
                  onClick={() => {
                    const loadReq = toast.loading("Loading...", {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    setDisableBtn(true);
                    setTimeout(() => {
                      setDisableBtn(false);
                    }, 3000);
                    dispatch(
                      deleteBranch({
                        token,
                        branchName: props.branchname,
                        merchantName : handleMerchant(decrypt(storedMerchant,key)).trim(),

                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data["messageAr"],
                          loadReq
                        );
                        window.location.reload();
                      } else if (res.payload.data.statusCode === 401) {
                        failedMessage(
                          "Please, login again",
                          "سجل الدخول مرة اخري من فضلك",
                          loadReq
                        );
                        navigate(
                          `/${decrypt(storedMerchant, key)}/manager/login`
                        );
                      } else {
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data["errorAr"],
                          loadReq
                        );
                      }
                    });
                  }}
                  disabled={disableDelBtn}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
