import React from "react";
import styled,{css} from "styled-components";

const StyledGiftContent = styled.div`
  .giftContent .welcomeContent .noteContent .noteHeadline {
    text-align: center;
    font-weight: 700;
    font-size: 0.9rem;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `color: #22af68`}
      ${() => merchant === "odonuts" && `color: #1d184e`}
    `}
  }
  .giftContent .welcomeContent .noteContent .notesubHeadline {
    text-align: center;
    font-weight: 700;
    font-size: 0.8rem;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `color: #22af68`}
      ${() => merchant === "odonuts" && `color: #1d184e`}
    `}
  }
  .giftContent .welcomeContent .bgWhite .bgGreen {
    border-radius: 20px;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `background-color: #22af68`}
      ${() => merchant === "odonuts" && `background-color: #1d184e`}
    `}
  }
  .giftContent .welcomeContent .bgWhite h6 span {
    font-size: 1.6rem;
    font-weight: 800;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `color: #22af68`}
      ${() => merchant === "odonuts" && `color: #1d184e`}
    `}
  }
  .giftContent .welcomeContent .bgWhite p {
    display: inline-block;
    padding: 10px 25px;
    font-weight: 700;
    font-size: 0.7rem;
    color: #ffffff;
    border-radius: 20px;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `background-color: #22af68`}
      ${() => merchant === "odonuts" && `background-color: #1d184e`}
    `}
  }
  .giftContent .welcomeContent .generateDiscountBtn {
    border-radius: 20px;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 10px 25px;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `background-color: #22af68`}
      ${() => merchant === "odonuts" && `background-color: #1d184e`}
    `}
  }
`;

export default function GfitContent(props) {
  return (
    <StyledGiftContent {...props}>{props.children}</StyledGiftContent>
  )
}
