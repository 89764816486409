import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { decrypt } from "n-krypta";

export const adminLogin = createAsyncThunk(
  "admin/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/ownerLogin",
        {
          MerchantName: data["merchantName"],
          phoneNumber: data["mobileNumber"],
          Password: data["password"],
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const createCashier = createAsyncThunk(
  "create/cashier",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/createCashier",
        {
          merchantName: data["merchantName"],
          firstName: data["firstName"],
          lastName: data["lastName"],
          mobileNumber: data["mobileNumber"],
        },
        {
          headers: {
            Authorization: `Bearer ${data["token"]}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const addBranch = createAsyncThunk(
  "add/branch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/createBranch",
        {
          GovernorateID: data["govID"],
          CityID: data["cityID"],
          BranchNameAr: data["branchName"],
          MerchantName: data["merchantName"],
        },
        {
          headers: {
            Authorization: `Bearer ${data["token"]}`,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "delete/branch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://evouchers.shareecoin.com/deleteBranch",
        {
          MerchantName: data["merchantName"],
          BranchNameAr: data["branchName"],
        },
        {
          headers: {
            Authorization: `Bearer ${data["token"]}`,
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const editBranch = createAsyncThunk(
  "edit/branch",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        "https://evouchers.shareecoin.com/editBranch",
        {
          BranchID: data.branchID,
          GovernorateID: data.govID,
          CityID: data.cityID,
          BranchNameAr: data.branchName,
          MerchantName: data.merchantName,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const forgetSendOTP = createAsyncThunk(
  "forget/passowrd",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/sendOTP",
        {
          phonenumber: info.phoneNumber,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteCashier = createAsyncThunk(
  "delete/cashier",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/DeleteCashier",
        {
          merchantName: data.merchantName,
          cashierId: data.cashierId,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const editCashier = createAsyncThunk(
  "edit/cashier",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "https://evouchers.shareecoin.com/editCashier",
        {
          cashierId: data.cashierID,
          firstName: data.firstName,
          lastName: data.lastName,
          mobileNumber: data.mobileNumber,
          BranchID: data.branchID,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      return response;
    } catch (error) {}
  }
);

const initialState = {
  login: {
    token: "",
    merchantName: "",
  },
  createCashier: {
    firstName: "",
    lastName: "",
    mobileNumber: "",
  },
  merchants: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    clearResults: (state, action) => {
      state.login.token = "";
      state.login.merchantName = "";
      state.createCashier.firstName = "";
      state.createCashier.lastName = "";
      state.createCashier.mobileNumber = "";
    },
    handleMerchantName: (state, action) => {
      state.login.merchantName = action.payload.merchantName;
    },
    handleMerchants: (state, action) => {
      state.merchants = action.payload.merchants;
    },
  },
  extraReducers: {
    [createCashier.fulfilled]: (state, action) => {},
    [addBranch.fulfilled]: (state, action) => {},
    [adminLogin.fulfilled]: (state, action) => {
      state.login.token = action.payload.data.token;
    },
    [forgetSendOTP.fulfilled]: (state, action) => {},
    [deleteCashier.fulfilled]: (state, action) => {},
    [deleteBranch.fulfilled]: (state, action) => {},
    [deleteBranch.rejected]: (state, action) => {},
    [editBranch.fulfilled]: (state, action) => {},
  },
});

export const { clearResults, handleMerchantName, handleMerchants } =
  adminSlice.actions;
export default adminSlice.reducer;
