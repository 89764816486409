import React from "react";
import styled, { css } from "styled-components";

const StyledOtpContent = styled.div`
  .otpInput {
    width: 100%;
    border: 2px solid #88c599 !important;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    font-family: "Montserrat", "IBMPlexSansArabic" !important;
  }
  .nextBtn {
    border-radius: 20px;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 10px 25px;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `background-color: #22af68`}
      ${() => merchant === "odonuts" && `background-color: #1d184e`}
    `}
  }
  .errorInput {
    border: 3px solid #ec1c24 !important;
    transition: all 0.2s;
  }
`;

export default function OtpContent(props) {

  return <StyledOtpContent {...props}>{props.children}</StyledOtpContent>;
}
