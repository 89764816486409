import React from "react";
import { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CongratsContent from "./CongratsContent";
import "./Congrats.css";
import { compare, decrypt } from "n-krypta";

export default function Congrats() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const barcode = useSelector((state) => state.green.customer.voucher.barcode);

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.customer.merchantName
  );
  const key = "ShareeCoin_1234567";

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/welcome`);
    }
  }, [storedMerchant]);

  return (
    <CongratsContent merchant={merchantNamee}>
      <div>
        {merchantNamee === "greenCafe" && (
          <Container>
            <Row>
              <Col xs={12}>
                <div className="throwPicContent">
                  <img
                    src={require("../../../../imgs/throw.png")}
                    alt="throw-pic"
                    className="throwPic img-responsive mb-4"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Container>
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <div className="congratsContent p-4 mb-3">
                <div className="bgWhite text-center p-3 mb-3">
                  {merchantNamee === "greenCafe" && (
                    <img
                      src={require("../../../../imgs/pointsIcon.png")}
                      alt="point-pic"
                      className="pointsIcon img-responsive"
                    />
                  )}

                  <h4 className="mt-3">{t("congrats")}</h4>
                  <p className="parathanks">{t("thankYou")}</p>
                </div>
                <div className="bgWhite text-center p-3">
                  <p className="mt-2 mb-2">{t("showCode")}</p>
                  <div className="giftCodeContent text-center px-3 py-1">
                    <img
                      src={`data:image/png;base64,${barcode}`}
                      alt="gift-code-pic"
                      className="giftCode img-responsive"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </CongratsContent>
  );
}
