import React, { useEffect } from "react";
import routes from "../../../routes";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { clearResults } from "../../../redux/features/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { persistor } from "../../../redux/store";
import { AiTwotoneHome } from "react-icons/ai";
import {FaStoreAlt} from "react-icons/fa";
import { compare, decrypt } from "n-krypta";
import styles from "./Home.module.scss";

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const key = "ShareeCoin_1234567";
  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    document.body.dir = "ltr";
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  return (
    <div className={styles.Home} dir="ltr">
      <div className={`${styles.navigation}`} dir="ltr">
        <ul>
          <li>
            <Link to="#">
              <span
                className={`${styles["icon-merchant-logo"]} ${styles["merchant-logo"]}`}
              ></span>
            </Link>
          </li>
          <li>
            <a>
              <span
                className={`${styles.icon}`}
                onClick={() => {
                  navigate(
                    `/${decrypt(storedMerchant, key)}/manager/dashboard/main`
                  );
                }}
              >
                <AiTwotoneHome />
              </span>
            </a>
            <a>
              <span
                className={`${styles.icon}`}
                onClick={() => {
                  navigate(
                    `/${decrypt(storedMerchant, key)}/manager/dashboard/stores`
                  );
                }}
              >
                <FaStoreAlt />
              </span>
            </a>
          </li>
        </ul>
        <button
          className={styles.logout}
          onClick={() => {
            dispatch(clearResults());
            navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
          }}
        >
          <AiOutlineLogout className={styles["logout-icon"]} />
        </button>
        {/* <div className="toggle">
      <BiMenu className="menu-icon" />
    </div> */}
      </div>
      <div className={styles["main-page"]} dir="ltr">
        <Outlet />
      </div>
    </div>
  );
}
