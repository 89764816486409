import {AiTwotoneHome} from "react-icons/ai";
import {FaStoreAlt} from "react-icons/fa";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Dashboard from "./components/manager/dashboard/Dashboard";
import Stores from "./components/manager/store/Stores";





const dashboardRoutes = [
  {
    path: "/manager/dashboard/main",
    name: "Dashboard",
    icon: <AiTwotoneHome />,
    component: Dashboard,
  },
  {
    path: "/manager/dashboard/stores",
    name: "Stores",
    icon: <FaStoreAlt />,
    component: Stores,
  },
];

export default dashboardRoutes;