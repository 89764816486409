import React from "react";
import Greenbg from "../../imgs/BG Gradient.png";
import styled, { css } from "styled-components";

const StyledLayoutCustomer = styled.div`
  .layoutContent {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    ${({ merchant }) => css`
      ${() =>
        merchant === "greenCafe" &&
        `background: linear-gradient(180deg, rgba(31,224,122,1) 0%, rgba(118,236,174,1) 27%, rgba(255,255,255,1) 100%);`}
      ${() =>
        merchant === "odonuts" &&
        `background: linear-gradient(180deg, rgba(29,24,78,1) 0%, rgba(83,79,120,1) 45%, rgba(255,255,255,1) 100%);`}
    `}
  }
`;

export default function LayoutContent(props) {
  return (
    <StyledLayoutCustomer {...props}>{props.children}</StyledLayoutCustomer>
  );
}
