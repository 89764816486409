import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import styles from "./ChangeLang.module.scss";

export default function ChangeLang() {
  const { t, i18n } = useTranslation();
  const handleChangeLang = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={`${styles.changeLangs}`}>
      {i18n.language === "ar" ? (
        <Button
          variant="light"
          size="sm"
          className={`${styles.langBtn}`}
          onClick={() => {
            handleChangeLang("en");
            document.body.dir = "ltr";
          }}
        >
          انجليزي
        </Button>
      ) : (
        <Button
          variant="light"
          size="sm"
          className={`${styles.langBtn}`}
          onClick={() => {
            handleChangeLang("ar");
            document.body.dir = "rtl";
          }}
        >
          Arabic
        </Button>
      )}
    </div>
  );
}
