import React from "react";
import styled, { css } from "styled-components";

const StyledCongratsContent = styled.div`
  .congratsContent .bgWhite p {
    font-weight: 800;
    font-size: 0.8rem;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `color: #22af68`}
      ${() => merchant === "odonuts" && `color: #1d184e`}
    `}
  }
`;

export default function CongratsContent(props) {
  return (
    <StyledCongratsContent {...props}>{props.children}</StyledCongratsContent>
  );
}
