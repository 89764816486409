import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

export default function MerchantPrivateRoute({ children }) {
  const [teams, setTeams] = useState([]);
  const merchantName = useParams().merchant;

  useEffect(() => {
    const merchantsData = async () => {
      try {
        const response = await axios.get(
          "https://evouchers.shareecoin.com/listMerchants"
        );
        let merchants = response.data.merchants;
        let result = merchants.map((a) => a["Merchant Name"]);
        setTeams(result);
      } catch (error) {
        console.log("errorr");
      }
    };
    merchantsData();
  }, []);


  if (teams.length !== 0) {
    if (teams.includes(merchantName) === true) {
      return children;
    } else {
      return <Navigate to={`/404`} />;
    }
  }
}
