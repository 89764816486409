import { toast } from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import i18n from "i18next";

const successMessage = (message, messageAr, pendingMessage) => {
  toast.update(pendingMessage, {
    render: i18n.language === "ar" ? messageAr : message,
    type: "success",
    position: i18n.language === "ar" ? "bottom-right" : "bottom-left",
    isLoading: false,
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export default successMessage;
