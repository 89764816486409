import React from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import styles from "./NewUser.module.scss";

export default function NewUser(props) {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <Modal.Body className={`${styles.modaBodyNewUser}`}>
          <Container>
            <Row>
              <Col xs={12} className="text-center mb-4">
                <img
                  src={require("../../../imgs/green-white-logo.png")}
                  alt="green-logo"
                  className={styles.greenLogo}
                />
              </Col>
              <Col xs={12}>
                <ul className={`${styles.notesList}`}>
                  <li>ارمي ال Can</li>
                  <li>سجل برقم تليفونك علشان تاخد النقط</li>
                  <li>امسح ال QR CODE</li>
                  <li>استبدل نقاط بهدية من Green Cafe</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
