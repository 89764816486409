import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  InputGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useNavigate, useParams } from "react-router-dom";
import {
  getBalance,
  sendOTP,
  handleVoucherDiscount,
} from "../../../../redux/features/customerSlice";
import { useEffect } from "react";
import Otp from "./otp/Otp";
import "./Gift.css";
import GfitContent from "./GfitContent";
import { compare, decrypt } from "n-krypta";

export default function Gift() {
  const { t, i18n } = useTranslation();

  const [btnDisable, setBtnDisable] = useState(false);

  const status = useSelector((state) => state.green.customer.info.isExist);
  const firstName = useSelector((state) => state.green.customer.info.firstName);
  const points = useSelector((state) => state.green.customer.balance.points);
  const egp = useSelector((state) => state.green.customer.balance.egp);
  const phoneNumber = useSelector(
    (state) => state.green.customer.info.phoneNumber
  );

  const generateDiscountValidation = yup.object().shape({
    discount: yup
      .number()
      .positive("discountNotValid")
      .max(egp, "discountNotValid")
      .required("discountReq"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showOTP, setShowOTP] = useState(false);

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.customer.merchantName
  );
  const key = "ShareeCoin_1234567";

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/welcome`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  return (
    <GfitContent merchant={merchantNamee}>
      <div className="giftContent">
        <Container>
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }} className="mb-3">
              <div className="welcomeContent p-4">
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      {status === true
                        ? `${t("welcome")}, ${firstName}`
                        : t("welcomeUknown")}
                    </Col>
                    <Col xs={12} md={6}>
                      <div className="d-grid">
                        <Button
                          variant="secondary"
                          size="sm"
                          className="registerBtn mb-3"
                          onClick={() => {
                            const loadReq = toast.loading(t("loading"), {
                              transition: Zoom,
                              position:
                                i18n.language === "ar"
                                  ? "bottom-right"
                                  : "bottom-left",
                            });
                            dispatch(
                              getBalance({
                                phoneNumber,
                                merchantName: handleMerchant(
                                  decrypt(storedMerchant, key)
                                ).trim(),
                              })
                            ).then((res) => {
                              if (res.payload.data.statusCode === 200) {
                                successMessage(
                                  res.payload.data["messageEn"],
                                  res.payload.data["messageAr"],
                                  loadReq
                                );
                                setBtnDisable(true);
                                setTimeout(() => {
                                  setBtnDisable(false);
                                }, 3000);
                              } else {
                                failedMessage(
                                  res.payload.data.error,
                                  res.payload.data["errorAr"],
                                  loadReq
                                );
                                navigate(
                                  `/${decrypt(storedMerchant, key)}/welcome`
                                );
                              }
                            });
                          }}
                          disabled={btnDisable}
                        >
                          {t("Refresh")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <div className="bgWhite p-3 mb-3">
                  <Container>
                    <Row>
                      <Col xs={12} md={{ span: 6, offset: 3 }}>
                        <div className="bgGreen p-3">
                          <h6>{t("balance")}</h6>
                          <Table>
                            <tbody>
                              <tr className="firstRow">
                                <td>
                                  <span className="d-block">{t("points")}</span>
                                  <span className="d-block">{t("egp")}</span>
                                </td>
                                <td className="txtRight">
                                  <span className="d-block">{points}</span>
                                  <span className="d-block">{egp}</span>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="noteContent p-3">
                  <Container>
                    <Row>
                      <Col xs={12}>
                        <h4 className="noteHeadline mb-3">
                          {t("exchangePoints")}
                        </h4>
                      </Col>
                      <Col xs={12} className="text-center">
                        {merchantNamee === "greenCafe" && (
                          <img
                            src={require("../../../../imgs/gifticons.png")}
                            alt="gift-icons"
                            className="giftIcons"
                          />
                        )}

                        <h5 className="notesubHeadline mt-3">
                          {t("getDiscountPoints")}
                        </h5>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <Formik
                  initialValues={{ discount: "" }}
                  onSubmit={(values,actions) => {
                    if (status === true) {
                      dispatch(
                        handleVoucherDiscount({
                          discountValue: values.discount,
                        })
                      );
                      const loadReq = toast.loading(t("loading"), {
                        transition: Zoom,
                        position:
                          i18n.language === "ar"
                            ? "bottom-right"
                            : "bottom-left",
                      });
                      setTimeout(() => {
                        actions.setSubmitting(false);
                      }, 3000);
                      dispatch(
                        sendOTP({
                          phoneNumber,
                          merchantName: handleMerchant(
                            decrypt(storedMerchant, key)
                          ).trim(),
                        })
                      ).then((res) => {
                        if (res.payload.data.statusCode === 200) {
                          successMessage(
                            res.payload.data.message,
                            res.payload.data["messageAr"],
                            loadReq
                          );
                          setShowOTP(true);
                        } else {
                          failedMessage(
                            res.payload.data.error,
                            res.payload.data["errorAr"],
                            loadReq
                          );
                        }
                      });
                    } else {
                      dispatch(
                        handleVoucherDiscount({
                          discountValue: values.discount,
                        })
                      );
                      navigate(`/${decrypt(storedMerchant, key)}/register`);
                    }
                  }}
                  validationSchema={generateDiscountValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col xs={12}>
                          <div className="bgWhite p-3">
                            <Form.Group
                              as={Row}
                              className="d-flex justify-content-around"
                            >
                              <Form.Label
                                column
                                xs={5}
                                sm={5}
                                className="dicountLabel"
                              >
                                <h6>{t("dependOnYourCredit")}</h6>
                                <Form.Text className="text-muted">
                                  {t("enterDiscountLabel")}
                                </Form.Text>
                              </Form.Label>

                              <Col xs={7} sm={7}>
                                <InputGroup className="mb-3 d-flex justify-content-end">
                                  <Form.Control
                                    aria-label="Default"
                                    placeholder="0"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="number"
                                    className="discountValue"
                                    name="discount"
                                    id="discount"
                                    onKeyPress={(e) => {
                                      var char = String.fromCharCode(e.which);
                                      if (!/[0-9]/.test(char)) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={handleChange}
                                    value={
                                      values.discount < 0 ? 0 : values.discount
                                    }
                                  />
                                  <InputGroup.Text className="inputDiscount">
                                    {t("egp")}
                                  </InputGroup.Text>
                                </InputGroup>
                                {errors.discount && touched.discount && (
                                  <Form.Text className="text-error">
                                    {t(errors.discount)}
                                  </Form.Text>
                                )}
                              </Col>
                            </Form.Group>
                          </div>
                        </Col>
                      </Row>
                      <div className="text-center">
                        <Button
                          variant="success"
                          size="md"
                          className="generateDiscountBtn mt-3"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {t("generateDiscount")}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
        <Otp
          show={showOTP}
          onHide={() => {
            setShowOTP(false);
          }}
        />
      </div>
    </GfitContent>
  );
}
