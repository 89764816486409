import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector } from "react-redux";
import styles from "./TransactionReport.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import NoData from "../../messages/NoData";
import { useTranslation } from "react-i18next";
import { compare, decrypt } from "n-krypta";

export default function TransactionReport(props) {
  const token = useSelector((state) => state.green.cashier.login.token);
  const [gridApi, setGridApi] = useState("");

  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");

  const key = "ShareeCoin_1234567";
  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.cashier.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
    }
  }, [storedMerchant]);

  window.history.pushState(null,"",window.location.href);
  window.onpopstate = ()=>{
    window.history.pushState(null,"/",window.location.href);
  }


  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  const columnDefs = [
    {
      headerName: "Gift Value",
      field: "VoucherValue",
    },
    {
      headerName: "Gift Code",
      field: "VoucherCode",
    },
    {
      headerName: "Receipt Number",
      field: "ReceiptNumber",
    },
    {
      headerName: "Date",
      field: "RedemptionDate",
    },
    {
      headerName: "Branch Name",
      field: "BranchNameAr",
    },
  ];

  const defaultColDef = {
    editable: false,
    filter: true,
    floatingFilter: false,
    resizable: false,
    minWidth: 150,
  };

  const { t, i18n } = useTranslation();

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .get("https://evouchers.shareecoin.com/cashierReport", {
        params: {
          merchantName: handleMerchant(decrypt(storedMerchant, key)).trim(),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          params.api.applyTransaction({ add: res.data.transactionsList });
        } else if (res.data.statusCode === 400) {
          navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
        } else {
          setMessageModal(true);
          i18n.language === "ar"
            ? setMessage(res.data.errorAr)
            : setMessage(res.data.error);
        }
      });
  };

  const navigate = useNavigate();

  const handleExport = () => {
    gridApi.exportDataAsCsv();
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>{t("reportDetails")}: </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <div className={`${styles.bgWhite} p-3 mt-3`}>
                  <div className="ag-theme-alpine" style={{ height: "400px" }}>
                    <AgGridReact
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <div className="d-grid mt-3">
                  <Button
                    variant="success"
                    size="sm"
                    className={`${styles.downloadReportBtn}`}
                    onClick={() => {
                      handleExport();
                    }}
                  >
                    DOWNLOAD REPORT
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <NoData
        show={messageModal}
        onHide={() => {
          setMessageModal(false);
        }}
        message={message}
      />
    </div>
  );
}
