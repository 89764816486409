import React from "react";
import styled,{css} from "styled-components";

const StyledWelcome = styled.div`
  .welcomeContent .proceedBtn {
    border-radius: 20px;
    font-weight: 700;
    font-size: 0.9rem;
    padding: 10px 25px;
    ${({ merchant }) => css`
      ${() => merchant === "greenCafe" && `background-color: #22af68`}
      ${() => merchant === "odonuts" && `background-color: #1d184e`}
    `}
  }
`;

export default function WelcomeContent(props) {
  return <StyledWelcome {...props}>{props.children}</StyledWelcome>;
}
