import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector } from "react-redux";
import { compare, decrypt } from "n-krypta";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./TotalPointsDetails.module.scss";

export default function TotalPointsDetails(props) {
  const token = useSelector((state) => state.green.admin.login.token);

  const [gridApi, setGridApi] = useState("");

  const columnDefs = [
    {
      headerName: "Date",
      field: "تاريخ اضافه النقاط",
    },
    {
      headerName: "Merchant Name",
      field: "اسم التاجر",
    },
    {
      headerName: "Branch Name",
      field: "اسم الفرع",
    },
    {
      headerName: "First Name",
      field: "الاسم الاول",
    },
    {
      headerName: "Last Name",
      field: "الاسم الاخير",
    },

    {
      headerName: "Customer Number",
      field: "رقم العميل",
    },
    {
      headerName: "Order Number",
      field: "رقم الفاتوره",
    },
    {
      headerName: "Cashier Mobile Number",
      field: "رقم الكاشير",
    },
    {
      headerName: "Total Added Points",
      field: "عدد النقاط المضافه",
    },
  ];

  const defaultColDef = {
    flex: 1,
    editable: false,
    filter: true,
    floatingFilter: false,
    resizable: false,
    minWidth: 250,
  };

  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  const navigate = useNavigate();
  const key = "ShareeCoin_1234567";

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  const merchantNamee = useParams().merchant;
  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    axios
      .get("https://evouchers.shareecoin.com/ownerReport", {
        params: {
          merchantName: handleMerchant(decrypt(storedMerchant, key)).trim(),
          queryType: "addedPointsDetails",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        params.api.applyTransaction({ add: res.data.data });
      });
  };

  const handleExport = () => {
    gridApi.exportDataAsCsv();
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>Total Added Points Details Report: </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <div className={`${styles.bgWhite} p-3 mt-3`}>
                  <div className="ag-theme-alpine" style={{ height: "400px" }}>
                    <AgGridReact
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <div className="d-grid mt-3">
                  <Button
                    variant="danger"
                    size="sm"
                    className={`${styles.downloadReportBtn}`}
                    onClick={() => {
                      handleExport();
                    }}
                  >
                    DOWNLOAD REPORT
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
