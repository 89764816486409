import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { FaUser, FaLock } from "react-icons/fa";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import styles from "./ForgetPassword.module.scss";
import ForgetOtp from "./forgetOtp/ForgetOtp";
import { forgetSendOTP } from "../../../../redux/features/adminSlice";

export default function ForgetPassword() {
  const [showOTP, setShowOTP] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const managerForgetPassword = yup.object().shape({
    mobileNumber: yup.string().required("Phone number is required"),
  });

  const convertNumbers2English = (string) => {
    return string
      .replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function (c) {
        return c.charCodeAt(0) - 0x06f0;
      });
  };


  return (
    <div className={`${styles.ForgetPasswordContent} p-3`}>
      <h5 className="mb-4 pt-5 px-5">Welcome to Green Cafe Rewards</h5>
      <Container>
        <Row
          className={`${styles.forgetCol} d-flex justify-content-center align-items-center`}
        >
          <Col xs={12} md={4} className="text-center">
            <img
              src={require("../../../../imgs/New green logo.png")}
              alt="green-logo"
              className={`${styles.greenLogo}`}
            />
          </Col>
          <Col xs={12} md={{ span: 6, offset: 1 }}>
            <h5 className="text-center mb-3">FORGET PASSWORD</h5>
            <Formik
              initialValues={{ mobileNumber: "" }}
              onSubmit={(values) => {
                const loadReq = toast.loading("Loading...", {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });
                const phoneNumberEn = convertNumbers2English(
                  values.mobileNumber
                );
                dispatch(
                  forgetSendOTP({
                    phoneNumber: phoneNumberEn,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    successMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                    setShowOTP(true);
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data["errorAr"],
                      loadReq
                    );
                  }
                });
              }}
              validationSchema={managerForgetPassword}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} className="mb-3">
                        <InputGroup className={`${styles.forgetInput} px-2`}>
                          <InputGroup.Text
                            className={`${styles.forgetInputIcon}`}
                          >
                            <FaUser />
                          </InputGroup.Text>
                          <Form.Control
                            type="tel"
                            maxLength={11}
                            placeholder="Phone Number"
                            className={`${styles.forgetInputField}`}
                            id="mobileNumber"
                            name="mobileNumber"
                            onChange={handleChange}
                            onKeyPress={(e) => {
                              var char = String.fromCharCode(e.which);
                              if (!/[0-9]/.test(char)) {
                                e.preventDefault();
                              }
                            }}
                            value={values.mobileNumber}
                          />
                        </InputGroup>
                        {errors.mobileNumber && touched.mobileNumber && (
                          <Form.Text className="text-error-green">
                            {errors.mobileNumber}
                          </Form.Text>
                        )}
                      </Col>
                      <div className="text-center">
                        <Button
                          variant="success"
                          type="submit"
                          size="sm"
                          className={`${styles.forgetBtn} mt-1`}
                        >
                          SEND
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
      <div className={`${styles.poweredLogoContent} px-5`}>
        <img
          src={require("../../../../imgs/Powered by white.png")}
          alt="powered-logo"
        />
      </div>
      <ForgetOtp
        show={showOTP}
        onHide={() => {
          setShowOTP(false);
        }}
      />
    </div>
  );
}
