import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const cashierLogin = createAsyncThunk(
  "cashier/login",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/cashierLogin",
        {
          phoneNumber: data["phoneNumber"],
          password: data["password"],
          branchID: data["branchId"],
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const redeemVoucher = createAsyncThunk(
  "cashier/redeem",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/redeemVoucher",
        {
          merchantName: data.merchantName,
          barcode: data.barcodeNum,
        },
        {
          headers: {
            Authorization: `Bearer ${data["token"]}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const burnVouch = createAsyncThunk(
  "cashier/burn",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `https://evouchers.shareecoin.com/updateVoucher/${data.recordID}/${data.airTableID}/${data.receiptID}/${data.cashierID}/${data.branchID}`
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const ChangePassword = createAsyncThunk(
  "cashier/change",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        "https://evouchers.shareecoin.com/cashierPasswordchange",
        {
          phoneNumber: data.mobileNumber,
          merchantName: data.merchantName,
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addPoints = createAsyncThunk(
  "cashier/addpoints",
  async (info, { rejectWithValue }) => {
    try {
      const result = axios.post(
        "https://evouchers.shareecoin.com/addPointsissuer",
        {
          merchantName: info.merchantName,
          receiptValue: info.receiptValue,
          receiptID: info.orderNumber,
          phoneNumber: info.customerMobile,
        },
        {
          headers: {
            Authorization: `Bearer ${info["token"]}`,
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const initialState = {
  login: {
    token: "",
    phoneNumber: "",
    password: "",
    branchName: "",
    merchantName:""
  },
  redeem: {
    cashierID: "",
    recordID: "",
    airTableID: "",
    branchID: "",
    scannedCode: "",
    scanStatus: false,
  },
  addPoints: {
    addPointsRole : "",
    customerMobile: "",
    points: "",
  },
};

export const cashierSlice = createSlice({
  name: "cashier",
  initialState,
  reducers: {
    cleareResults: (state, action) => {
      state.login.token = "";
      state.login.phoneNumber = "";
      state.login.password = "";
      state.login.branchName = "";
      state.redeem.cashierID = "";
      state.redeem.recordID = "";
      state.redeem.airTableID = "";
      state.redeem.scannedCode = "";
      state.redeem.branchID = "";
      state.redeem.scanStatus = false;
      state.login.merchantName=""
    },
    clearRedeem: (state, action) => {
      state.redeem.cashierID = "";
      state.redeem.recordID = "";
      state.redeem.airTableID = "";
      state.redeem.scannedCode = "";
      state.redeem.branchID = "";
      state.redeem.scanStatus = false;
      
    },
    handleScanCode: (state, action) => {
      state.redeem.scannedCode = action.payload.scannedCode;
    },
    resetScan: (state, action) => {
      state.redeem.scannedCode = "";
    },
    handleAddPointsRole : (state,action)=>{
      state.addPoints.addPointsRole = action.payload.addPointsRole;
    }
  },
  extraReducers: {
    [cashierLogin.fulfilled]: (state, action) => {
      if (
        action.payload.data.statusCode === 200 ||
        action.payload.data.statusCode === 201
      ) {
        state.login.token = action.payload.data.token;
        state.redeem.branchID = action.payload.data.branchID;
        state.login.phoneNumber = action.meta.arg.phoneNumber;
        state.login.merchantName = action.meta.arg.merchantName;

      }
    },
    [redeemVoucher.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.redeem.cashierID = action.payload.data.cashierID;
        state.redeem.recordID = action.payload.data.RecordID;
        state.redeem.airTableID = action.payload.data.AirTableID;
      }
    },
    [burnVouch.fulfilled]: (state, action) => {},
    [ChangePassword.fulfilled]: (state, action) => {},
  },
});

export const { cleareResults, clearRedeem, handleScanCode, resetScan,handleAddPointsRole } =
  cashierSlice.actions;
export default cashierSlice.reducer;
