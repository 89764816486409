import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clearResults } from "../../redux/features/adminSlice";
import { cleareResults } from "../../redux/features/cashierSlice";
import styles from "./NotFound.module.scss";

export default function NotFound() {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(clearResults());
    dispatch(cleareResults());
  },[])

  return (
    <div className={`${styles.notFoundContent}`}>
      <div className={`${styles.bgGrey} p-5`}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h4>404</h4>
              <p>Page Not Found</p>
            </Col>
            <Col xs={12} className="text-center">
              <img
                src={require("../../imgs/sad.png")}
                alt="sad-emoji"
                className={`${styles.sadFace}`}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
