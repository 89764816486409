import React,{useEffect} from "react";
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillMessage } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { compare,decrypt } from "n-krypta";
import styles from "./NoData.module.scss";

export default function NoData(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();



  const key = "ShareeCoin_1234567";
  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.cashier.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
    }
  }, [storedMerchant]);

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12} className="text-center">
                <AiFillMessage className={`${styles.messageIcon} mb-3`} />
              </Col>
              <Col xs={12}>
                <h4>{props.message}</h4>
              </Col>
              <Col xs={12} className="text-center mt-3">
                <Button
                  variant="success"
                  size="sm"
                  className={`${styles.backBtn}`}
                  onClick={() => {
                    navigate(
                      `/${decrypt(storedMerchant, key)}/cashier/dashboard`
                    );
                    window.location.reload();
                  }}
                >
                  {t("goDash")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
