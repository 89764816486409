import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "./features/customerSlice";
import cashierReducer from "./features/cashierSlice";
import adminReducer from "./features/adminSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer,persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  customer: customerReducer,
  cashier: cashierReducer,
  admin: adminReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: {
    green: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

