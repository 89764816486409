import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AiOutlineFileSearch } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { MdPassword, MdAddBox } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useHistory } from "react-router-dom";
import { cleareResults } from "../../../redux/features/cashierSlice";
import TransactionReport from "../report/TransactionReport";
import ChangeLang from "../../changeLang/ChangeLang";
import { compare, decrypt } from "n-krypta";
import styles from "./Cashier.module.scss";

export default function Cashier(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showReportModal, setShowReportModal] = useState(false);

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.cashier.login.merchantName
  );

  const storedAddPointsRole = useSelector(
    (state) => state.green.cashier.addPoints.addPointsRole
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  const key = "ShareeCoin_1234567";


  return (
    <div className={`${styles.cashierHome} p-2`}>
      <div className={`${styles.cashierMain}`}>
        <Container>
          <Row className="d-flex justify-content-between">
            <Col xs={12} className="mb-4">
              <ChangeLang />
            </Col>
            <Col
              xs={12}
              className="d-flex justify-content-between align-items-center"
            >
              <div className={`${styles.cashierTitle} mb-3`}>
                <h4>
                  {t("welcomeGreenCafeCashier")},{" "}
                  {handleMerchant(decrypt(storedMerchant, key)).trim()}
                </h4>
                <p>Food and Beverage</p>
              </div>
              <div>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    navigate(
                      `/${decrypt(storedMerchant, key)}/cashier/change-password`
                    );
                  }}
                  className={`${styles.changeBtn} ms-3`}
                >
                  <MdPassword />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    dispatch(cleareResults());
                    navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
                  }}
                  className={`${styles.logoutBtn}`}
                >
                  <RiLogoutBoxRLine />
                </Button>
              </div>
            </Col>
            <Col xs={12} md={6} className="mb-3 mb-md-0">
              <div>
                <div
                  className={`${styles.redeemBtn} p-3`}
                  onClick={() => {
                    navigate(`/${decrypt(storedMerchant, key)}/cashier/redeem`);
                  }}
                >
                  <img
                    src={require("../../../imgs/redeem.png")}
                    alt="redeem-pic"
                  />
                  <p className="mt-2">{t("redeemGift")}</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div>
                <div
                  className={`${styles.reportBtn} text-center p-3`}
                  onClick={() => {
                    setShowReportModal(true);
                  }}
                >
                  <AiOutlineFileSearch />
                  <p className="mt-2">{t("transReport")}</p>
                </div>
              </div>
            </Col>
            {compare(storedAddPointsRole, "hasRole", key) === true && (
              <Col xs={12} md={12} className="mt-2">
                <div>
                  <div
                    className={`${styles.reportBtn} text-center p-3`}
                    onClick={() => {
                      navigate(
                        `/${decrypt(storedMerchant, key)}/cashier/addpoints`
                      );
                    }}
                  >
                    <MdAddBox />
                    <p className="mt-2">{t("addPoints")}</p>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <TransactionReport
        show={showReportModal}
        onHide={() => {
          setShowReportModal(false);
        }}
      />
    </div>
  );
}
