import React, { useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Scanner from "./Scanner";
import styles from "./ScanBarcode.module.scss";
import { useState } from "react";

export default function ScanBarcode(props) {
  const scannedCode = useSelector(
    (state) => state.green.cashier.redeem.scannedCode
  );

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
      >
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>Scan Barcode</h4>
                <Scanner />
                <div className={`${styles.scanCodeReuslt} p-2`}>
                  {scannedCode}
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
