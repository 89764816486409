import React from "react";
import { toast, Zoom } from "react-toastify";
import { Container, Row, Col, Form, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ForgetOtp.module.scss";


export default function ForgetOtp(props) {
  const otpValidation = yup.object().shape({
    firstDigit: yup.string().required("كود OTP مطلوب"),
    secondDigit: yup.string().required("كود OTP مطلوب"),
    thirdDigit: yup.string().required("كود OTP مطلوب"),
    fourthDigit: yup.string().required("كود OTP مطلوب"),
    fifthDigit: yup.string().required("كود OTP مطلوب"),
    sixthDigit: yup.string().required("كود OTP مطلوب"),
  });

  const handleClickotp = (first, last) => {
    if (document.getElementById(first).value !== "") {
      document.getElementById(last).focus();
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t,i18n} = useTranslation();

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className={`${styles.ModalBodyOTP}`}>
          <Formik
            initialValues={{
              firstDigit: "",
              secondDigit: "",
              thirdDigit: "",
              fourthDigit: "",
              fifthDigit: "",
              sixthDigit: "",
            }}
            onSubmit={(values, { resetForm }) => {
              const loadReq = toast.loading("Loading...", {
                transition: Zoom,
                position:
                  i18n.language === "ar" ? "bottom-right" : "bottom-left",
              });
              var otpText = "";
              otpText += values.firstDigit;
              otpText += values.secondDigit;
              otpText += values.thirdDigit;
              otpText += values.fourthDigit;
              otpText += values.fifthDigit;
              otpText += values.sixthDigit;
            }}
            validationSchema={otpValidation}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="px-4">
                <Row>
                  <Col xs={12} className="mb-3">
                    <MdOutlineMobileFriendly className="mx-1" />
                    {t("OTP")} :
                  </Col>
                </Row>
                <Form.Group
                  as={Row}
                  className={`${styles.digitsParent} mb-3 p-3 d-flex justify-content-center`}
                >
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.firstDigit &&
                        touched.firstDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="firstDigit"
                      id="firstDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("firstDigit", "secondDigit");
                      }}
                      onChange={handleChange}
                      value={values.firstDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.secondDigit &&
                        touched.secondDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="secondDigit"
                      id="secondDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("secondDigit", "thirdDigit");
                      }}
                      onChange={handleChange}
                      value={values.secondDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.thirdDigit &&
                        touched.thirdDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="thirdDigit"
                      id="thirdDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("thirdDigit", "fourthDigit");
                      }}
                      onChange={handleChange}
                      value={values.thirdDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.fourthDigit &&
                        touched.fourthDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="fourthDigit"
                      id="fourthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("fourthDigit", "fifthDigit");
                      }}
                      onChange={handleChange}
                      value={values.fourthDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.fifthDigit &&
                        touched.fifthDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="fifthDigit"
                      id="fifthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("fifthDigit", "sixthDigit");
                      }}
                      onChange={handleChange}
                      value={values.fifthDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.sixthDigit &&
                        touched.sixthDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="sixthDigit"
                      id="sixthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onChange={handleChange}
                      value={values.sixthDigit}
                    />
                  </Col>
                </Form.Group>

                <div className="d-grid">
                  <Button
                    variant="success"
                    className={`${styles.nextBtn}`}
                    type="submit"
                  >
                    {t("proceed")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
