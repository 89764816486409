import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ChangeLang from "../changeLang/ChangeLang";
import LayoutContent from "./LayoutContent";
import "./Layout.css";

export default function Layout(props) {
  const merchantNamee = useParams().merchant;

  return (
    <LayoutContent merchant={merchantNamee}>
      <div className="layoutContent">
        <div className="langContent p-4">
          <ChangeLang />
        </div>
        <Container>
          <Row>
            <Col xs={12} className="layoutHeader">
              {
                (merchantNamee === "greenCafe" && (
                  <img
                    src={require("../../imgs/green-white-logo.png")}
                    alt="green-logo"
                    className="greenLogo"
                  />
                ))
              }
              {
                (merchantNamee === "odonuts" && (
                  <img
                    src={require("../../imgs/3213.png")}
                    alt="green-logo"
                    className="greenLogo"
                  />
                ))
              }
            </Col>
            <Col xs={12} className="layoutBody mb-4">
              {props.customerelement}
            </Col>
          </Row>
        </Container>
      </div>
    </LayoutContent>
  );
}
