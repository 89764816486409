import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import styles from "./QrModa.module.scss";

export default function QrModal(props) {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
      >
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>QR Code</h4>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xs={12} className="mt-3">
                <p>Scan and Check Out Your Customer Experience</p>
              </Col>
              <Col xs={12} md={{ span: 6, offset: 3 }} className="text-center">
                <img
                  src={require("../../../../imgs/QR for Green cafe landing page.png")}
                  alt="qr-green"
                  className={styles.qrImage}
                />
              </Col>
              <Col xs={12} md={{ span: 6, offset: 3 }} className="text-center my-3">
                <Button
                  variant="success"
                  size="md"
                  className={`${styles.downloadQrBtn}`}
                >
                  Download QR Code
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
