import React, { useState } from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  CloseButton,
} from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import AddCashier from "./addCashier/AddCashier";
import styles from "./Cashiers.module.scss";
import EditCashier from "./editCashier/EditCashier";
import { useEffect } from "react";
import { toast, Zoom } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { deleteCashier } from "../../../../redux/features/adminSlice";
import { useNavigate, useParams } from "react-router-dom";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useTranslation } from "react-i18next";
import DelCashier from "./delCashier/DelCashier";
import { compare, decrypt } from "n-krypta";

export default function Cashiers(props) {
  const [addcashier, setAddcashier] = useState(false);
  const [editcashier, setEditcashier] = useState(false);
  const [delcashier, setDelcashier] = useState(false);

  const [cashiers, setCashiers] = useState([]);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [cashierid, setCashierid] = useState("");
  const [branchid, setBranchid] = useState("");

  const [delCashierID, setDelCashierID] = useState("");
  const merchantNamee = useParams().merchant;

  const key = "ShareeCoin_1234567";

  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  useEffect(() => {
    axios
      .get("https://evouchers.shareecoin.com/listCashiers", {
        params: {
          merchantName: handleMerchant(decrypt(storedMerchant, key)).trim(),
        },
      })
      .then((res) => {
        setCashiers(res.data.cashiers);
      });
  }, []);

  const token = useSelector((state) => state.green.admin.login.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >
        <Modal.Header className={`${styles.cashiersHeader}`} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Cashiers</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${styles.cashiersBody}`}>
          <Container>
            <Row>
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <div className="d-grid">
                  <Button
                    variant="danger"
                    size="lg"
                    className={`${styles.addCashierBtn}`}
                    onClick={() => {
                      setAddcashier(true);
                    }}
                  >
                    Add Cashier
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={{ span: 6, offset: 3 }}>
                <div className={`${styles.bgWhite} p-3 mt-3`}>
                  <Container>
                    <Row>
                      <Col xs={4} className={`${styles.cashierName} mb-2`}>
                        Cashiers:
                      </Col>
                      <Col xs={12}>
                        {cashiers.map((cash) => {
                          return (
                            <div
                              key={Math.random() * 100000}
                              className={`${styles.cashierElement} px-2 py-1 d-flex justify-content-between align-items-center mb-2`}
                            >
                              <span>{`${cash["First Name"]} ${cash["Last Name"]} - ${cash["Mobilenumber"]}`}</span>
                              <div>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="mx-1"
                                  onClick={() => {
                                    setEditcashier(true);
                                    setFirstname(cash["First Name"]);
                                    setLastname(cash["Last Name"]);
                                    setMobilenumber(cash["Mobilenumber"]);
                                    setCashierid(cash["CashierID"]);
                                    setBranchid(cash["BranchID"]);
                                  }}
                                >
                                  <FiEdit />
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mx-1"
                                  onClick={() => {
                                    setDelcashier(true);
                                    setDelCashierID(cash["CashierID"]);
                                  }}
                                >
                                  <BsTrash />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <AddCashier
        show={addcashier}
        onHide={() => {
          setAddcashier(false);
        }}
      />
      <EditCashier
        show={editcashier}
        onHide={() => {
          setEditcashier(false);
        }}
        firstname={firstname}
        lastname={lastname}
        mobilenumber={mobilenumber}
        cashierid={cashierid}
        branchid={branchid}
      />
      <DelCashier
        show={delcashier}
        onHide={() => {
          setDelcashier(false);
        }}
        cashierid={delCashierID}
      />
    </div>
  );
}
