import React, { useEffect } from "react";
import { Container, Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import ChangeLang from "../../changeLang/ChangeLang";
import { Formik } from "formik";
import * as yup from "yup";
import { FaMobile, FaLock } from "react-icons/fa";
import { toast, Zoom } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";
import { ChangePassword } from "../../../redux/features/cashierSlice";
import { useNavigate, useParams } from "react-router-dom";
import { compare, decrypt } from "n-krypta";
import styles from "./CashierChangePassword.module.scss";

export default function CashierChangePassword() {
  const { t, i18n } = useTranslation();

  const cashierchangeValidation = yup.object().shape({
    phoneNumber: yup
      .string()
      .required("phoneNumberReq")
      .matches(/^01[0125][0-9]{8}$/gm, "phoneNumberNotValid"),
    currentPassword: yup.string().required("currentPassReq"),
    newPassword: yup.string().required("newPassReq"),
    cpassword: yup
      .string()
      .required("confirmPassReq")
      .oneOf([yup.ref("newPassword"), null], "passwordMatchReq"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storedMobileNumber = useSelector(
    (state) => state.green.cashier.login.phoneNumber
  );

  const key = "ShareeCoin_1234567";
  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.cashier.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/cashier/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  return (
    <div className={`${styles.changeContent} p-3`}>
      <h5 className="mb-4 pt-5 px-5">{t("WelcomeGreen")}</h5>
      <Container>
        <Row
          className={`${styles.changeCol} d-flex justify-content-center align-items-center`}
        >
          <Col xs={12} md={4} className="text-center">
            {merchantNamee === "greenCafe" && (
              <img
                src={require("../../../imgs/green-white-logo.png")}
                alt="green-logo"
                className={`${styles.greenLogo}`}
              />
            )}
            {merchantNamee === "odonuts" && (
              <img
                src={require("../../../imgs/3213.png")}
                alt="green-logo"
                className={`${styles.greenLogo}`}
              />
            )}
          </Col>
          <Col xs={12} md={{ span: 4, offset: 2 }}>
            <h5 className="text-center mb-3 mt-3">{t("changePass")}</h5>
            <Formik
              initialValues={{
                phoneNumber: storedMobileNumber,
                currentPassword: "",
                newPassword: "",
                cpassword: "",
              }}
              onSubmit={(values, actions) => {
                const loadReq = toast.loading("Loading...", {
                  transition: Zoom,
                  position:
                    i18n.language === "ar" ? "bottom-right" : "bottom-left",
                });
                setTimeout(() => {
                  actions.setSubmitting(false);
                }, 3000);
                dispatch(
                  ChangePassword({
                    mobileNumber: values.phoneNumber,
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword,
                    merchantName: handleMerchant(
                      decrypt(storedMerchant, key)
                    ).trim(),
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    navigate(
                      `/${decrypt(storedMerchant, key)}/cashier/dashboard`
                    );
                    successMessage(
                      res.payload.data.message,
                      res.payload.data["messageAr"],
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.error,
                      res.payload.data["errorAr"],
                      loadReq
                    );
                  }
                });
              }}
              validationSchema={cashierchangeValidation}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <InputGroup className={`${styles.changeInput} px-2`}>
                        <InputGroup.Text
                          className={`${styles.changeInputIcon}`}
                        >
                          <FaMobile />
                        </InputGroup.Text>
                        <Form.Control
                          type="tel"
                          placeholder={t("mobileNumber")}
                          maxLength={11}
                          className={`${styles.changeInputField}`}
                          id="phoneNumber"
                          name="phoneNumber"
                          onKeyPress={(e) => {
                            var char = String.fromCharCode(e.which);
                            if (!/[0-9]/.test(char)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={handleChange}
                          value={values.phoneNumber}
                          disabled={true}
                        />
                      </InputGroup>
                      {errors.phoneNumber && touched.phoneNumber && (
                        <Form.Text className="text-error-green">
                          {t(errors.phoneNumber)}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="mb-3">
                      <InputGroup className={`${styles.changeInput} px-2`}>
                        <InputGroup.Text
                          className={`${styles.changeInputIcon}`}
                        >
                          <FaLock />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          placeholder={t("currentPass")}
                          maxLength={11}
                          className={`${styles.changeInputField}`}
                          id="currentPassword"
                          name="currentPassword"
                          onChange={handleChange}
                          value={values.currentPassword}
                        />
                      </InputGroup>
                      {errors.currentPassword && touched.currentPassword && (
                        <Form.Text className="text-error-green">
                          {t(errors.currentPassword)}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="mb-3">
                      <InputGroup className={`${styles.changeInput} px-2`}>
                        <InputGroup.Text
                          className={`${styles.changeInputIcon}`}
                        >
                          <FaLock />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          placeholder={t("newPass")}
                          maxLength={11}
                          className={`${styles.changeInputField}`}
                          id="newPassword"
                          name="newPassword"
                          onChange={handleChange}
                          value={values.newPassword}
                        />
                      </InputGroup>
                      {errors.newPassword && touched.newPassword && (
                        <Form.Text className="text-error-green">
                          {t(errors.newPassword)}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} className="mb-3">
                      <InputGroup className={`${styles.changeInput} px-2`}>
                        <InputGroup.Text
                          className={`${styles.changeInputIcon}`}
                        >
                          <FaLock />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          placeholder={t("confirmPass")}
                          maxLength={11}
                          className={`${styles.changeInputField}`}
                          id="cpassword"
                          name="cpassword"
                          onChange={handleChange}
                          value={values.cpassword}
                        />
                      </InputGroup>
                      {errors.cpassword && touched.cpassword && (
                        <Form.Text className="text-error-green">
                          {t(errors.cpassword)}
                        </Form.Text>
                      )}
                    </Col>
                    <div className="text-center">
                      <Button
                        variant="danger"
                        type="submit"
                        size="sm"
                        className={`${styles.changeBtn} mt-1`}
                        disabled={isSubmitting}
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <div className={`${styles.poweredLogoContent} px-5`}>
        <img
          src={require("../../../imgs/Powered by white.png")}
          alt="powered-logo"
        />
      </div>
    </div>
  );
}
