import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

export default function CustomerPrivateRoute({condition,children}) {

    const merchantNamee = useParams().merchant;

    if(condition === "" || condition === false){
        return <Navigate to={`/${merchantNamee}/welcome`}/>
    }else{
        return children;
    }
}
