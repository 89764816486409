import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {
  clearResults,
  getBalance,
  handleMerchantName,
} from "../../../../redux/features/customerSlice";
import { useTranslation } from "react-i18next";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WelcomeContent from "./WelcomeContent";
import "./Welcome.css";
import { decrypt, encrypt } from "n-krypta";
import NewUser from "../../../messages/newUser/NewUser";

export default function Welcome() {
  const [showModalOtp, setShowModalOtp] = useState(false);
  const [showModalNewuser, setShowModalNewuser] = useState(false);

  const { t, i18n } = useTranslation();
  const sendOTPValidation = yup.object().shape({
    phoneNumber: yup
      .string()
      .required("phoneNumberReq")
      .matches(/^01[0125][0-9]{8}$/gm, "phoneNumberNotValid"),
  });

  const dispatch = useDispatch();

  const convertNumbers2English = (string) => {
    return string
      .replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function (c) {
        return c.charCodeAt(0) - 0x06f0;
      });
  };

  useEffect(() => {
    dispatch(clearResults());
  }, []);

  const navigate = useNavigate();

  const merchantNamee = useParams().merchant;
  const key = "ShareeCoin_1234567";

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  return (
    <WelcomeContent merchant={merchantNamee}>
      <div>
        {merchantNamee === "greenCafe" && (
          <Container>
            <Row>
              <Col xs={12}>
                <div className="throwPicContent">
                  <img
                    src={require("../../../../imgs/throw.png")}
                    alt="throw-pic"
                    className="throwPic img-responsive mb-4"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Container>
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <div className="welcomeContent p-4">
                <Formik
                  initialValues={{
                    phoneNumber: "",
                  }}
                  onSubmit={(values, actions) => {
                    const loadReq = toast.loading(t("loading"), {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    const phoneNumberEn = convertNumbers2English(
                      values.phoneNumber
                    );
                    setTimeout(() => {
                      actions.setSubmitting(false);
                    }, 3000);
                    dispatch(
                      getBalance({
                        phoneNumber: phoneNumberEn,
                        merchantName: handleMerchant(merchantNamee).trim(),
                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data["messageEn"],
                          res.payload.data["messageAr"],
                          loadReq
                        );
                        dispatch(
                          handleMerchantName({
                            merchantName: encrypt(merchantNamee, key),
                          })
                        );
                        navigate(`/${merchantNamee}/balance`);
                      } else {
                        if (merchantNamee === "greenCafe") {
                          setShowModalNewuser(true);
                        }
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data["errorAr"],
                          loadReq
                        );
                      }
                    });
                  }}
                  validationSchema={sendOTPValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Container>
                        <Form.Group as={Row} className="inputGroup p-3">
                          <Form.Label column sm={6} className="inputLabel">
                            {t("enterMobile")}
                          </Form.Label>
                          <Col sm={6}>
                            <Form.Control
                              type="tel"
                              maxLength={11}
                              placeholder={t("mobileNumber")}
                              className="inputField"
                              id="phoneNumber"
                              name="phoneNumber"
                              onChange={handleChange}
                              onKeyPress={(e) => {
                                var char = String.fromCharCode(e.which);
                                if (!/[0-9]/.test(char)) {
                                  e.preventDefault();
                                }
                              }}
                              values={values.phoneNumber}
                            />
                          </Col>
                          {errors.phoneNumber && touched.phoneNumber && (
                            <Form.Text className="text-error">
                              {t(errors.phoneNumber)}
                            </Form.Text>
                          )}
                        </Form.Group>
                        <div className="text-center">
                          <Button
                            type="submit"
                            variant="success"
                            size="md"
                            className="proceedBtn mt-3"
                            disabled={isSubmitting}
                          >
                            {t("confirm")}
                          </Button>
                        </div>
                      </Container>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <NewUser
        show={showModalNewuser}
        onHide={() => {
          setShowModalNewuser(false);
        }}
      />
    </WelcomeContent>
  );
}
