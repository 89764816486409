import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import { Formik } from "formik";
import * as yup from "yup";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../../messages/Successful";
import failedMessage from "../../../../messages/Failed";
import styles from "./EditBranch.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { editBranch } from "../../../../../redux/features/adminSlice";
import { useNavigate, useParams } from "react-router-dom";
import { compare, decrypt } from "n-krypta";

export default function EditBranch(props) {
  const [govs, setGovs] = useState([]);
  const [cities, setCities] = useState([]);
  const [govID, setGovID] = useState(null);

  useEffect(() => {
    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        Function: "ListGovernorates",
      })
      .then((res) => {
        setGovs(res.data["Governates List"]);
      });

    axios
      .post("https://hook.eu1.make.com/obhpcxaunwqei43gvndk7a3ciwixiiw7", {
        GovernateID: govID === null ? props.govid : govID,
        Function: "ListCites",
      })
      .then((res) => {
        setCities(res.data["List of Cities"]);
      });
  }, [props.govid, govID]);

  const editBranchValidation = yup.object().shape({
    branchName: yup.string().required("Branch name is required"),
    governorate: yup.string().required("Branch governorate is required"),
    city: yup.string().required("Branch city is required"),
  });

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.green.admin.login.token);

  const key = "ShareeCoin_1234567";

  const merchantNamee = useParams().merchant;
  const storedMerchant = useSelector(
    (state) => state.green.admin.login.merchantName
  );

  useEffect(() => {
    if (compare(merchantNamee, storedMerchant, key) === false) {
      navigate(`/${decrypt(storedMerchant, key)}/manager/login`);
    }
  }, [storedMerchant]);

  const handleMerchant = (name) => {
    const capitalLetter = name.replace(/[^A-Z]/g, "");
    const capitalPostion = name.search(capitalLetter);
    const partTwo = name.slice(capitalPostion);
    const partOne = name.substring(capitalPostion, -1);
    const final = partOne.charAt(0).toUpperCase() + partOne.slice(1);
    return `${final} ${partTwo}`;
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.bgModal}`}
      >
        <Modal.Body className={`${styles.bgModalGrey}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <h4>Edit Branch Details</h4>
                <Formik
                  initialValues={{
                    branchName: props.branchname,
                    governorate: props.govid,
                    city: props.cityid,
                  }}
                  onSubmit={(values,actions) => {
                    const loadReq = toast.loading("Loading...", {
                      transition: Zoom,
                      position:
                        i18n.language === "ar" ? "bottom-right" : "bottom-left",
                    });
                    setTimeout(() => {
                      actions.setSubmitting(false);
                    }, 3000);
                    dispatch(
                      editBranch({
                        branchName: values.branchName,
                        govID: values.governorate,
                        cityID: values.city,
                        branchID: props.branchid,
                        token,
                        merchantName : handleMerchant(decrypt(storedMerchant,key)).trim(),
                      })
                    ).then((res) => {
                      if (res.payload.data.statusCode === 200) {
                        successMessage(
                          res.payload.data.message,
                          res.payload.data["messageAr"],
                          loadReq
                        );
                        window.location.reload();
                      } else if (res.payload.data.statusCode === 401) {
                        failedMessage(
                          "Please, login again",
                          "سجل الدخول مرة اخري من فضلك",
                          loadReq
                        );
                        navigate(
                          `/${decrypt(storedMerchant, key)}/manager/login`
                        );
                      } else {
                        failedMessage(
                          res.payload.data.error,
                          res.payload.data["errorAr"],
                          loadReq
                        );
                      }
                    });
                  }}
                  validationSchema={editBranchValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    isSubmitting,
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className={`${styles.bgWhite} p-3 mt-3`}>
                          <Container>
                            <Form.Group as={Row} controlId="formGridState">
                              <Form.Label
                                column
                                sm={5}
                                className={`${styles.branchInfo}`}
                              >
                                Branch Details:
                              </Form.Label>
                              <Col sm={7}>
                                <Form.Group as={Row}>
                                  <Col sm={12} className="mb-2">
                                    <Form.Control
                                      type="text"
                                      placeholder="Branch Name"
                                      className={`${styles.branchInput}`}
                                      name="branchName"
                                      id="branchName"
                                      onChange={handleChange}
                                      value={values.branchName}
                                    />
                                    {errors.branchName &&
                                      touched.branchName && (
                                        <Form.Text className="text-error">
                                          {errors.branchName}
                                        </Form.Text>
                                      )}
                                  </Col>
                                  <Col sm={12} className="mb-2">
                                    <Form.Select
                                      className={`${styles.addBranchInput}`}
                                      name="governorate"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "governorate",
                                          e.target.value
                                        );
                                        setGovID(e.target.value);
                                      }}
                                      value={values.governorate}
                                    >
                                      <option value="">Select Governate</option>
                                      {govs.map((gover) => {
                                        return (
                                          <option
                                            value={gover.GovernateID}
                                            key={Math.random() * 10000}
                                          >
                                            {i18n.language === "en"
                                              ? gover["governorate_name_en"]
                                              : gover["governorate_name_ar"]}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                    {errors.governorate &&
                                      touched.governorate && (
                                        <Form.Text className="text-error">
                                          {errors.governorate}
                                        </Form.Text>
                                      )}
                                  </Col>
                                  <Col sm={12} className="mb-2">
                                    <Form.Select
                                      className={`${styles.addBranchInput}`}
                                      name="city"
                                      onChange={(e) => {
                                        setFieldValue("city", e.target.value);
                                      }}
                                      value={values.city}
                                    >
                                      <option value="">Select City</option>
                                      {cities.map((city) => {
                                        return (
                                          <option
                                            value={city.cityID}
                                            key={Math.random() * 10000}
                                          >
                                            {i18n.language === "en"
                                              ? city["city_name_en"]
                                              : city["city_name_ar"]}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                    {errors.city && touched.city && (
                                      <Form.Text className="text-error">
                                        {errors.city}
                                      </Form.Text>
                                    )}
                                  </Col>
                                </Form.Group>
                              </Col>
                            </Form.Group>
                          </Container>
                        </div>
                        <div className="d-grid mt-3">
                          <Button
                            variant="success"
                            type="submit"
                            className={`${styles.saveBtn}`}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
